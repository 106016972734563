<template>
  <section class="section">
    <h2 class="subtitle service">
      {{
        slug === "teachers-attestation"
          ? $ml.get(currentService.slug)
          : currentService.title
      }}
    </h2>

    <hr />

    <div class="grayed-footer" style="margin-top: 25px">
      <div class="field is-grouped-left">
        <div class="control">
          <router-link
            :tag="'button'"
            :class="'button is-info'"
            :service="currentService"
            :to="{
              name: 'claims.personal-list',
              params: { slug: currentService.slug }
            }"
          >
            {{ $ml.get("show_journal_new_claims") }}
          </router-link>

          <router-link
            :style="'margin-left: 20px'"
            :tag="'button'"
            :disabled="isButtonDisabled"
            :class="'button is-success'"
            :service="currentService"
            v-if="
              ['teachers-attestation', 'principals-attestation'].includes(
                slug
              ) && showButtonAddNewClaimAttestation
            "
            :to="{
              name: 'service.wizard',
              params: {
                slug: currentService.slug,
                step: 1,
                declarertype: declarerType,
                employmenttype: employmentType,
                area: area
              }
            }"
          >
            {{ $ml.get("create_claim") }}
          </router-link>

          <router-link
            v-else-if="
              !['teachers-attestation', 'principals-attestation'].includes(
                slug
              ) && currentService.slug !== 'special-needs-social-services'
            "
            :style="'margin-left: 20px'"
            :tag="'button'"
            :class="'button is-success'"
            :service="currentService"
            :to="{
              name: 'service.wizard',
              params: { slug: currentService.slug, step: 1 }
            }"
          >
            {{ makeClaimButtonName }}
          </router-link>

          <!--http://jira.samgau.com/browse/UOALM-219-->
          <!--a href="#"
                       :style="'margin-left: 20px'"
                       :class="'button is-success'"
                       disabled="disabled"
                       v-else
                    >{{ makeClaimButtonName }}</a-->
          <p v-if="messageBlocked">
            <b>{{ messageBlocked }}</b>
          </p>
        </div>
      </div>
    </div>

    <hr v-if="slug !== 'teachers-attestation' || showTeachers" />

    <div
      class="content wizard-content teachers-attestation-declarer-types"
      v-if="currentService.slug === 'teachers-attestation' && showTeachers"
    >
      <h4>{{ $ml.get("declarer_type") }}</h4>

      <div class="field">
        <div class="select">
          <select
            v-model="declarerType"
            name="disability_category_id"
            v-validate="'required'"
            id="disability_category_id"
          >
            <option value="0" disabled selected
              >{{ $ml.get("select_declarer_type") }}
            </option>
            <option
              v-for="type in availableDeclarerTypes"
              :key="`item_${type.number}`"
              :value="type.number"
              >{{ $ml.get(type.slug) }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="isAreaNeedToVisible">
        <h4>{{ $ml.get("area") }}</h4>

        <div class="field">
          <div class="select">
            <select
              v-model="area"
              name="area_id"
              v-validate="'required'"
              id="area_id"
            >
              <option value="0" disabled selected>
                {{ $ml.get("select_area") }}
              </option>
              <option
                v-for="type in availableAreas"
                :key="`item_${type.id}`"
                :value="type.id"
                >{{ $ml.get(type.slug) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div
      class="content wizard-content teachers-attestation-declarer-types"
      v-if="currentService.slug === 'principals-attestation'"
    >
      <h4>{{ $ml.get("declarer_type") }}</h4>

      <div class="field">
        <div class="select">
          <select
            v-model="declarerType"
            name="disability_category_id"
            v-validate="'required'"
            id="disability_category_id"
          >
            <option value="0" disabled selected
              >{{ $ml.get("select_declarer_type") }}
            </option>
            <option
              v-for="type in availableDeclarerTypes"
              :key="`item_${type.number}`"
              :value="type.number"
              >{{ $ml.get(type.slug) }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="declarerType !== 0" class="field">
        <h4>{{ $ml.get("employment") }}</h4>
        <div class="field">
          <div class="select">
            <select
              name="emplyment_id"
              id="emplyment_id"
              v-model="employmentType"
              v-validate="'required'"
            >
              <option value="0" disabled selected>
                {{ $ml.get("select_employment") }}
              </option>
              <option
                v-for="type in availableEmploymentTypes"
                :key="`item_${type.number}`"
                :value="type.number"
              >
                {{ $ml.get(type.slug) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="isAreaNeedToVisible">
        <h4>{{ $ml.get("area") }}</h4>

        <div class="field">
          <div class="select">
            <select
              v-model="area"
              name="area_id"
              v-validate="'required'"
              id="area_id"
            >
              <option value="0" disabled selected>
                {{ $ml.get("select_area") }}
              </option>
              <option
                v-for="type in availableAreas"
                :key="`item_${type.id}`"
                :value="type.id"
                >{{ $ml.get(type.slug) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div
      class="content wizard-content"
      v-html="
        slug === 'teachers-attestation'
          ? $ml.get('teacher_attestation_translate')
          : currentService.description
      "
    ></div>
    <!--
    <div class="content wizard-content">
      <h4>Внимание!</h4>
      <p>
        Прием документов для прохождения аттестации на присвоение
        (подтверждение) квалификационных категорий для педагогических работников
        и приравненных к ним лиц, занимающих должности в организациях
        образования, реализующих общеобразовательные учетные программы
        начального, основного среднего и общего среднего образования и
        специальные учебные программы будет производится с 7 июня 9:00 до 18:00
        часов 20 июня 2019 года
      </p>
    </div>
    -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Api from "../../services/api/api-resource/api-instance";
import _ from "lodash";

export default {
  name: "ServiceMain",
  props: {
    slug: {}
  },
  data() {
    return {
      messageBlocked: null,
      declarerType: 0,
      area: 0,
      employmentType: 0,
      canCallNewClaimTeachers: false,
      canCallNewClaimPrincipals: false,
      forceRerender: false
    };
  },
  watch: {
    slug(value) {
      this.fetchDictionaries();
    },
    declarerType() {
      this.employmentType = 0;
      this.area = 0;
    }
  },
  computed: {
    ...mapGetters({
      services: "services/getList",
      declarerTypes: "services/getDeclarerTypes",
      employmentTypes: "services/getEmploymentTypes",
      areas: "services/getAreas",
      canCallNewClaim: "user/getCanCallNewClaim"
    }),
    makeClaimButtonName() {
      // yo yo yo
      let buttonName = "Подать заявление";

      if (this.currentService.slug === "special-needs-social-services") {
        buttonName = "Подать заявление на запись в ПМПК";
      }

      return buttonName;
    },
    currentService() {
      this.initialize();
      return this.services.find(service => service.slug === this.slug) || {};
    },
    showTeachers() {
      if (this.slug === "teachers-attestation") {
        return this.canCallNewClaimTeachers && this.canCallNewClaim;
      }
      if (this.slug === "principals-attestation") {
        return this.canCallNewClaimPrincipals && this.canCallNewClaim;
      }
      return parseInt(process.env["VUE_APP_SHOW_TEACHERS"]);
    },
    showButtonAddNewClaimAttestation() {
      switch (this.currentService.slug) {
        case "principals-attestation":
        case "teachers-attestation":
          console.log(this.showTeachers);
          return this.showTeachers;
        default:
          return false;
      }
    },
    availableDeclarerTypes() {
      return this.declarerTypes(this.slug).filter(
        type => type.availableForClaim
      );
    },
    availableAreas() {
      return this.areas;
    },
    availableEmploymentTypes() {
      // В поле «Занятость» справочник «Дошкольное воспитание и обучение» отображается только при выборе типа заявителя «Руководители организации образования»
      let types = this.employmentTypes;
      if (this.declarerType === 1) {
        types = types.filter(type => type.number !== 1);
      }
      return types;
    },
    isButtonDisabled() {
      let result = false;
      switch (this.currentService.slug) {
        case "teachers-attestation":
          result =
            !this.declarerType || (this.isAreaNeedToVisible && !this.area);
          break;

        case "principals-attestation":
          result =
            !this.declarerType ||
            (this.isAreaNeedToVisible && !this.area) ||
            !this.employmentType;
          break;
      }
      return result;
    },
    isAreaNeedToVisible() {
      let result = false;
      switch (this.currentService.slug) {
        case "teachers-attestation":
          result = ![0, 6, 9].includes(this.declarerType);
          break;
        case "principals-attestation":
          // Поле «Район» отображается при выборе всех типов заявителей, кроме «Техническое и профессиональное, послесреднее образование», «Институт повышения квалификации (ИПК), методические кабинеты (центры)»
          result = ![0, 3, 6].includes(this.employmentType);
          break;
      }
      return result;
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions("services", ["fetchDeclarerTypes", "fetchEmploymentTypes"]),
    ...mapActions("user", ["checkCanCallNewClaim"]),
    initialize() {
      this.fetchDictionaries();
      this.checkCanCallNewClaim(this.slug);
      if (this.slug === "teachers-attestation") {
        Api.make("Settings")
          .list({ tag: "teachers-attestation" })
          .then(response => {
            let canCallNewClaimTeachers = _.find(response.data, {
              name: "add_claim_for_all"
            });
            if (canCallNewClaimTeachers) {
              if (canCallNewClaimTeachers.value_type_name == "bool") {
                if (canCallNewClaimTeachers.value == "true") {
                  this.canCallNewClaimTeachers = true;
                }
              }
            }
          });
      } else if (this.slug === "principals-attestation") {
        Api.make("Settings")
          .list({ tag: "principals-attestation" })
          .then(response => {
            let canCallNewClaimPrincipals = _.find(response.data, {
              name: "add_claim_for_all"
            });
            if (canCallNewClaimPrincipals) {
              if (canCallNewClaimPrincipals.value_type_name === "bool") {
                if (canCallNewClaimPrincipals.value === "true") {
                  this.canCallNewClaimPrincipals = true;
                }
              }
            }
          });
      }
    },
    fetchDictionaries() {
      if (
        ["teachers-attestation", "principals-attestation"].includes(this.slug)
      ) {
        if (!this.declarerTypes(this.slug).length) {
          this.fetchDeclarerTypes(this.slug);
        }
        this.fetchEmploymentTypes();
      }
    },
    triggerRerender() {
      this.forceRerender = !this.forceRerender;
    }
  }
};
</script>

<style lang="scss" scoped>
.teachers-attestation-declarer-types {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 300px;
    overflow-y: scroll;

    li {
      cursor: pointer;
      background-color: #1976d2;
      color: #fff;
      padding: 15px;

      &:hover {
        background-color: #0c538f;
      }

      &.active {
        background-color: #0c538f;
      }
    }
  }

  .service-description {
    margin-top: 15px;
  }
}
</style>
