<template>
  <section>
    <div class="pageloader is-active">
      <span class="title">Обработка данных от системы входа...</span>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Api from "../../services/api/api-resource/api-instance";
import { displayError, getErrorsText } from "../../services/helper";

export default {
  name: "ReturnParser",
  data: function() {
    return {};
  },
  methods: {
    requestApiToken: function(access_token) {
      return Api.make("Authorization")
        .doLogin(access_token)
        .then(response => {
          const token = response.data.token;
          const profile = response.data.profile;
          return this.loginUser({ token, profile });
        })
        .then(() => {
          return Api.make("Authorization")
            .getProfile()
            .then(response => {
              this.setUsersProfile(response.data.profile);
              this.setUserRole(response.data.role);
              this.setUserPermissions(response.data.role.permissions);
            });
        })
        .catch(error => {
          displayError(this, getErrorsText(error));
        });
    },
    ...mapActions({
      loginUser: "user/loginUser",
      setUsersProfile: "user/setUsersProfile",
      setUserPermissions: "user/setUserPermissions",
      setUserRole: "user/setUserRole"
    })
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn"
    })
  },
  mounted() {
    const subst = window.location.hash.substr(1);
    const prms = new URLSearchParams(subst);
    let config = {};
    for (const param of prms) {
      config[param[0]] = param[1];
    }
    //console.log(config);
    // we have access_token?
    if (config.access_token) {
      if (config.id_token) {
        localStorage.setItem("ids_id_token", config.id_token);
      }
      // @todo: request backend, log user in, fill profile, redirect to home
      this.requestApiToken(config.access_token)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          // @todo: declined (non 200 code)
          this.$router.push({ name: "home" });
        });
    } else {
      // @todo: decline login (show 401 message)
      this.$router.push({ name: "home" });
    }
    /*
      UserinfoApi.get("/connect/userinfo", {
        headers: {
          Authorization: `Bearer ${config["access_token"]}`
        }
      }).then(response => {
        console.log(response.data);
      });
*/
  }
};
</script>

<style scoped></style>
